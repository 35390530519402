import React from 'react'
import './Notification.scss'
import { Container } from 'react-bootstrap'

const Notification = () => {
  return (
    <Container>
      Notification
    </Container>
  )
}

export default Notification
