import React, { useState, useEffect, memo, useCallback, useMemo } from 'react';
import { Tree, Input, Spin } from 'antd';
import { fetchAllProvince, fetchDistrictsByProvinces } from '../../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import removeAccents from 'remove-accents';
import { LoadingOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';

const TreeDirectory = () => {
    const [treeData, setTreeData] = useState([]);
    const [originalTreeData, setOriginalTreeData] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        fetchProvinces();
        const quyhoachParams = searchParams.get('quyhoach');
        if (quyhoachParams) {
            const quyhoachKeys = quyhoachParams.split(',').map((id) => `plan-${id}`);
            setCheckedKeys(quyhoachKeys);
        }
    }, []);

    useEffect(() => {
        if (debouncedSearchTerm) {
            filterTreeData(debouncedSearchTerm);
        } else {
            setTreeData(originalTreeData);
            setExpandedKeys([]);
        }
    }, [debouncedSearchTerm]);

    const fetchProvinces = async () => {
        try {
            setLoading(true);
            const provinces = await fetchAllProvince();
            const provincesData = await Promise.all(
                provinces.map(async (province) => {
                    const districts = await fetchDistricts(province.TinhThanhPhoID);
                    return {
                        title: province.TenTinhThanhPho,
                        key: `province-${province.TinhThanhPhoID}`,
                        children: districts,
                        isLeaf: false,
                    };
                }),
            );
            setTreeData(provincesData);
            setOriginalTreeData(provincesData);
        } catch (error) {
            console.error('Error fetching provinces:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDistricts = async (provinceId) => {
        try {
            const districts = await fetchDistrictsByProvinces(provinceId);
            return await Promise.all(
                districts.map(async (district) => {
                    const planningData = await fetchPlanningData(district.DistrictID);
                    return {
                        title: district.DistrictName,
                        key: `district-${district.DistrictID}`,
                        children: planningData,
                        isLeaf: false,
                    };
                }),
            );
        } catch (error) {
            console.error('Error fetching districts:', error);
            return [];
        }
    };

    const fetchPlanningData = async (districtId) => {
        try {
            const response = await fetch(`https://apilandinvest.gachmen.org/quyhoach1quan/${districtId}`);
            const planningData = await response.json();
            return planningData.map((plan) => ({
                title: plan.description,
                key: `plan-${plan.id}`,
                isLeaf: true,
            }));
        } catch (error) {
            console.error('Error fetching planning data:', error);
            return [];
        }
    };

    const onCheck = useCallback(
        (checkedKeysValue) => {
            if (!Array.isArray(checkedKeysValue)) return;

            setCheckedKeys(checkedKeysValue);

            const quyhoachIds = checkedKeysValue
                .filter((key) => key?.startsWith('plan-'))
                .map((key) => key?.split('-')[1])
                .filter((id) => id != null);

            if (quyhoachIds.length > 0) {
                searchParams.set('quyhoach', quyhoachIds.toString());
            } else {
                searchParams.delete('quyhoach');
            }

            navigate({
                search: searchParams.toString(),
            });
        },
        [navigate, searchParams],
    );

    const filterTreeData = (searchTerm) => {
        setLoadingSearch(true);
        const normalizedTerm = removeAccents(searchTerm).toLowerCase();

        const filterNodes = (nodes) => {
            return nodes.reduce((acc, node) => {
                const nodeMatch = removeAccents(node.title).toLowerCase().includes(normalizedTerm);
                const filteredChildren = node.children ? filterNodes(node.children) : [];

                if (nodeMatch || filteredChildren.length > 0) {
                    return [
                        ...acc,
                        {
                            ...node,
                            children: filteredChildren,
                        },
                    ];
                }
                return acc;
            }, []);
        };

        const filteredData = filterNodes(originalTreeData);

        const collectExpandedKeys = (nodes) => {
            return nodes.reduce((keys, node) => {
                if (node.children && node.children.length > 0) {
                    return [...keys, node.key, ...collectExpandedKeys(node.children)];
                }
                return keys;
            }, []);
        };

        const newExpandedKeys = collectExpandedKeys(filteredData);
        console.log('====================================');
        console.log('newExpandedKeys', newExpandedKeys);
        console.log('====================================');

        setTreeData(filteredData);
        setExpandedKeys(newExpandedKeys);
        setLoadingSearch(false);
    };

    const onExpand = (expandedKeysValue) => {
        console.log('====================================');
        console.log(expandedKeysValue);
        console.log('====================================');
        setExpandedKeys(expandedKeysValue);
    };

    return (
        <>
            <Search
                loading={loadingSearch}
                placeholder="Search provinces or districts"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: 8 }}
            />
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
                </div>
            ) : (
                <Tree
                    showLine
                    treeData={treeData}
                    checkable
                    checkedKeys={checkedKeys}
                    onCheck={onCheck}
                    expandedKeys={expandedKeys}
                    onExpand={onExpand}
                />
            )}
        </>
    );
};

export default memo(TreeDirectory);
